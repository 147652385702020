'use strict';

require("tempusdominus-bootstrap-4")

var initDateTimePickers = (function() {
  var $dateTimePicker = $('.datetimepicker');

  function init($this) {
    var options = {
      icons: {
        time: 'far fa-clock',
        date: 'far fa-calendar',
        up: 'far fa-arrow-up',
        down: 'far fa-arrow-down',
        previous: 'far fa-chevron-left',
        next: 'far fa-chevron-right',
        today: 'far fa-calendar-check-o',
        clear: 'far fa-trash',
        close: 'far fa-times'
      },
      buttons: { showClose: true },
      autoclose: false,
      sideBySide: true,
      keepOpen: true,
      debug: true,
      focusOnShow: false
    };

    $this.datetimepicker(options);
  }

  if ($dateTimePicker.length) {
    $dateTimePicker.each(function() {
      init($(this));
    });
  }
});

document.addEventListener('turbolinks:load', initDateTimePickers);
