import { Controller } from 'stimulus'

import Rails from "@rails/ujs"

import Dropzone from 'dropzone'
import 'dropzone/dist/dropzone.css'

Dropzone.autoDiscover = false;

export default class extends Controller {
  static targets = [ "dropArea" ]
  static values = { gallery: Number }

  connect () {
    var options = {
      acceptedFiles: ".jpg,.jpeg,.png",
      uploadMultiple: true,
      method: "POST",
      paramName: function(n) { // This sends form-data as files[] for rails strong parameters
        return "files[]";
      }
    }
    this.dropzone = new Dropzone(this.dropAreaTarget, options);

    this.dropzone.on("successmultiple", this.imageUploaded.bind(this))
  }

  imageUploaded(event) {
    var gallery_image_ids = JSON.parse(event[0].xhr.response)

    gallery_image_ids.forEach(element => {
      Rails.ajax({
        type: "GET",
        url: `/content/galleries/${this.galleryValue}/gallery_images/${element}`,
        dataType: 'script'
      })
    });
  }
}
