'use strict'

import 'select2'
import 'select2/dist/css/select2.css'
import '@ttskch/select2-bootstrap4-theme/dist/select2-bootstrap4.css'


var initSelect2 = (function() {
  var $selects = $('[data-toggle="select"]')

  function init($this) {
    var options = {
      theme: 'bootstrap4',
    };

    $this.select2(options);
  }

  if ($selects.length) {
    $selects.each(function() {
      init($(this));
    });
  }
});

document.addEventListener('turbolinks:load', initSelect2);