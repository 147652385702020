'use strict';

var scrollToTop = (function() {
  var offset = 250;
  var duration = 300;

  $(window).on({
    'scroll': function() {
      if ($(this).scrollTop() > offset) {
        $('.back-to-top').fadeIn(duration)
      } else {
        $('.back-to-top').fadeOut(duration)
      }
      return
    }
  })
  
  $('.back-to-top').on({
    'click': function(event) {
      event.preventDefault();
      $('html, body').animate({ scrollTop: 0 }, duration);
      return false;
    }
  })
});
  

document.addEventListener('ready', scrollToTop);
document.addEventListener('turbolinks:load', scrollToTop);
