document.addEventListener("turbolinks:load", () => {
  let cardElement = document.querySelector("#card-element")
  if (cardElement !== null)
    setupStripe()

  let newCard = document.querySelector("#use-new-card")
  if (newCard !== null) {
    newCard.addEventListener("click", (event) => {
      event.preventDefault()
      document.querySelector("#payment-form").classList.remove('d-none')
      document.querySelector("#existing-card").classList.add('d-none')
    })
  }
})

function setupStripe() {
  const stripe_key = document.querySelector("meta[name='stripe-key']").getAttribute("content")
  const stripe_account = document.querySelector("meta[name='stripe-account']").getAttribute("content")
  const stripe = Stripe(stripe_key, { stripeAccount: stripe_account })

  const elements = stripe.elements()
  var style = {
    base: {
      color: "#495057",
      lineHeight: "1.5rem"
    }
  };
  const card = elements.create('card', {style: style})
  card.mount('#card-element')

  var displayError = document.getElementById('card-errors')

  card.addEventListener('change', (event) => {
    if (event.error) {
      displayError.textContent = event.error.message
    } else {
      displayError.textContent = ''
    }
  })

  const form = document.querySelector("#payment-form")
  let paymentIntentId = form.dataset.paymentIntent
  let setupIntentId = form.dataset.setupIntent

  if (paymentIntentId) {
    if (form.dataset.status == "requires_action") {
      stripe.confirmCardPayment(paymentIntentId, {
        setup_future_usage: 'off_session'
      }).then((result) => {
        if (result.error) {
          displayError.textContent = result.error.message
          form.querySelector("#card-details").classList.remove("d-none")
        } else {
          form.submit()
        }
      })
    }
  }

  form.addEventListener('submit', (event) => {
    event.preventDefault()

    let name = form.querySelector("#name_on_card").value
    let payment_data = {
      payment_method_data: {
        card: card,
        billing_details: {
          name: name
        }
      }
    }

    if (paymentIntentId) {
      // Complete payment intent
      stripe.confirmCardPayment(paymentIntentId, {
        payment_method: payment_data.payment_method_data,
        setup_future_usage: 'off_session'
      }).then((result) => {
        if (result.error) {
          displayError.textContent = result.error.message
          form.querySelector("#card-details").classList.remove("d-none")
        } else {
          addHiddenField(form, "payment_method_id", result.paymentIntent.payment_method)
          form.submit()
        }
      })

    } else if (setupIntentId) {
      // Updating a card or subscribing with a trial (using a SetupIntent)
      stripe.confirmCardSetup(setupIntentId, {
        payment_method: payment_data.payment_method_data
      }).then((result) => {
        if (result.error) {
          displayError.textContent = result.error.message
        } else {
          addHiddenField(form, "payment_method_id", result.setupIntent.payment_method)
          form.submit()
        }
      })
    } else {
      // Subscribing with no trial
      payment_data.payment_method_data.type = 'card'
      stripe.createPaymentMethod(payment_data.payment_method_data).then((result) => {
        if (result.error) {
          displayError.textContent = result.error.message
        } else {
          addHiddenField(form, "payment_method_id", result.paymentMethod.id)
          form.submit()
        }
      })
    }

  })
}

function addHiddenField(form, name, value) {
  let hiddenInput = document.createElement("input")
  hiddenInput.setAttribute("type", "hidden")
  hiddenInput.setAttribute("name", name)
  hiddenInput.setAttribute("value", value)
  form.appendChild(hiddenInput)
}
