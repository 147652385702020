import Trix from "trix"
import Rails from "@rails/ujs"

let lang = Trix.config.lang;
Trix.config.toolbar = {
  getDefaultHTML: function() {
    return `
    <div class="trix-button-row">
      <span class="trix-button-group trix-button-group--text-tools" data-trix-button-group="text-tools">
        <button type="button" class="trix-button trix-button--icon trix-button--icon-bold" data-trix-attribute="bold" data-trix-key="b" title="${lang.bold}" tabindex="-1">${lang.bold}</button>
        <button type="button" class="trix-button trix-button--icon trix-button--icon-italic" data-trix-attribute="italic" data-trix-key="i" title="${lang.italic}" tabindex="-1">${lang.italic}</button>
        <button type="button" class="trix-button trix-button--icon trix-button--icon-strike" data-trix-attribute="strike" title="${lang.strike}" tabindex="-1">${lang.strike}</button>
        <button type="button" class="trix-button trix-button--icon trix-button--icon-link" data-trix-attribute="href" data-trix-action="link" data-trix-key="k" title="${lang.link}" tabindex="-1">${lang.link}</button>
      </span>
      <span class="trix-button-group trix-button-group--block-tools" data-trix-button-group="block-tools">
        <button type="button" class="trix-button trix-button--icon trix-button--icon-heading-1" data-trix-attribute="heading1" title="${lang.heading1}" tabindex="-1">${lang.heading1}</button>
        <button type="button" class="trix-button trix-button--icon trix-button--icon-quote" data-trix-attribute="quote" title="${lang.quote}" tabindex="-1">${lang.quote}</button>
        <button type="button" class="trix-button trix-button--icon trix-button--icon-code" data-trix-attribute="code" title="${lang.code}" tabindex="-1">${lang.code}</button>
        <button type="button" class="trix-button trix-button--icon trix-button--icon-bullet-list" data-trix-attribute="bullet" title="${lang.bullets}" tabindex="-1">${lang.bullets}</button>
        <button type="button" class="trix-button trix-button--icon trix-button--icon-number-list" data-trix-attribute="number" title="${lang.numbers}" tabindex="-1">${lang.numbers}</button>
        <button type="button" class="trix-button trix-button--icon trix-button--icon-decrease-nesting-level" data-trix-action="decreaseNestingLevel" title="${lang.outdent}" tabindex="-1">${lang.outdent}</button>
        <button type="button" class="trix-button trix-button--icon trix-button--icon-increase-nesting-level" data-trix-action="increaseNestingLevel" title="${lang.indent}" tabindex="-1">${lang.indent}</button>
      </span>
      <span class="trix-button-group trix-button-group--file-tools" data-trix-button-group="file-tools">
        <button type="button" class="trix-button trix-button--icon trix-button--icon-attach" data-trix-action="attachFiles" title="${lang.attachFiles}" tabindex="-1">${lang.attachFiles}</button>
        <button type="button" class="trix-button trix-button--icon trix-button--icon-video" data-trix-action="embedVideo" title="Embed video" tabindex="-1">Embed video</button>
        <button type="button" class="trix-button trix-button--icon trix-button--icon-video-plus" data-trix-action="embedPopupVideo" title="Embed popup video" tabindex="-1">Embed popup video</button>
      </span>
      <span class="trix-button-group-spacer"></span>
      <span class="trix-button-group trix-button-group--history-tools" data-trix-button-group="history-tools">
        <button type="button" class="trix-button trix-button--icon trix-button--icon-undo" data-trix-action="undo" data-trix-key="z" title="${lang.undo}" tabindex="-1"></button>
        <button type="button" class="trix-button trix-button--icon trix-button--icon-redo" data-trix-action="redo" data-trix-key="shift+z" title="${lang.redo}" tabindex="-1"></button>
      </span>
    </div>
    <div class="trix-dialogs" data-trix-dialogs>
      <div class="trix-dialog trix-dialog--link" data-trix-dialog="href" data-trix-dialog-attribute="href">
        <div class="trix-dialog__link-fields">
          <input type="url" name="href" class="trix-input trix-input--dialog" placeholder="${lang.urlPlaceholder}" aria-label="${lang.url}" required data-trix-input>
          <div class="flex">
            <input type="button" class="btn btn-secondary btn-small mr-1" value="${lang.link}" data-trix-method="setAttribute">
            <input type="button" class="btn btn-tertiary outline btn-small" value="${lang.unlink}" data-trix-method="removeAttribute">
          </div>
        </div>
        <div data-behavior="embed_container">
          <div class="link_to_embed link_to_embed--new">
            Would you like to embed media from this site?
            <input class="btn btn-primary btn-sm ml-3" type="button" data-behavior="embed_url" value="Yes, embed it">
          </div>
        </div>
      </div>

      <div class="modal fade" id="videoChooser" data-backdrop="static" data-keyboard="false" tabindex="-1" role="dialog" aria-labelledby="videoChooserLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered modal-lg">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="videoChooserLabel">Select a video</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Cancel">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="container-fluid">
                <p>Please select a video to embed.</p>
                <div class="form-group">
                  <label>Search by video name</label>
                  <input type="text" id="videoSearch" class="form-control"></input>
                </div>
                <p id="loadingVideos">
                    Loading videos. Please wait...
                </p>
                <div class="list-group" id="videos">
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  `
  }
}


class VideoEmbedController {
  constructor(element) {
    console.log("Hello from VideoEmbedController")

    this.element = element
    this.editor = element.editor
    this.toolbar = element.toolbarElement
    this.button = this.toolbar.querySelector("[data-trix-action='embedVideo']")
    this.popupButton = this.toolbar.querySelector("[data-trix-action='embedPopupVideo']")
    this.videoChooser = this.toolbar.querySelector("#videoChooser")
    this.videoSearch = this.toolbar.querySelector('#videoSearch')
    this.keyupStack = [];
    this.videosElement = this.videoChooser.querySelector("#videos")

    this.installEventHandlers()
  }

  installEventHandlers() {
    this.button.addEventListener("click", this.showVideoChooser.bind(this))
    this.popupButton.addEventListener("click", this.showVideoChooser.bind(this))
    this.videoSearch.addEventListener("keyup", this.search.bind(this))
    this.videoSearch.addEventListener("keydown", this.preventSubmit.bind(this))

    const targetNode = this.videosElement
    const config = { childList: true, subtree: false }
    const callback = function(mutationsList, observer) {
      let controller = this
      for (let mutation of mutationsList) {
        if (mutation.type === 'childList') {
          for (let node of mutation.addedNodes) {
            if (node.tagName === "A") {
              console.log("Child node added: ", node)
              node.addEventListener("click", function() {
                controller.onVideoSelected(node)
              })
            }
          }
        }
      }
    };

    // The bind call sets "this" inside the callback to refer to this controller object
    const observer = new MutationObserver(callback.bind(this));
    observer.observe(targetNode, config);
  }

  showVideoChooser(event) {
    console.log("showVideoChooser()")

    if (event.target.getAttribute('data-trix-action') == 'embedPopupVideo') {
      this.options = 'popupVideo';
    } else {
      this.options = '';
    }

    $(this.videoChooser).modal("show")

    Rails.ajax({
      type: 'get',
      url: "/content/videos/chooser_index",
      dataType: 'script'
    })
  }

  search(event) {
    if (event.key == "Enter") {
      return;
    }

    this.keyupStack.push(1);
    setTimeout(function () {
      this.keyupStack.pop();
      if (this.keyupStack.length === 0) {
        console.log("searching for: " + this.videoSearch.value);

        Rails.ajax({
          type: 'get',
          url: `/content/videos/chooser_index?${encodeURIComponent("q[title_cont]")}=${encodeURIComponent(this.videoSearch.value)}`,
          dataType: 'script'
        })
      }
    }.bind(this), 300);
  }

  preventSubmit(event) {
    if (event.key == "Enter") {
      event.preventDefault();
    }
  }

  onVideoSelected(node) {
    console.log("onVideoSelected() ", node);
    let videoId = node.getAttribute('data-video-id');
    console.log("wistiaMediaId = ", videoId);
    $(this.videoChooser).modal("hide");
    this.fetch(videoId);
  }

  fetch(videoId) {
    let resource = 'videos';
    if (this.options == 'popupVideo') {
      resource = 'popup_videos';
    }

    Rails.ajax({
      url: `/${resource}/${encodeURIComponent(videoId)}`,
      type: 'get',
      error: this.reset.bind(this),
      success: this.createAttachmemt.bind(this)
    })
  }

  createAttachmemt(embed) {
    if (embed == null)
      return

    let attachment = new Trix.Attachment(embed)
    this.editor.insertAttachment(attachment)
    this.element.focus()
  }

  reset() {
    console.log("Failed to embed video")
  }
}

class YoutubeEmbedController {
  constructor(element) {
    this.pattern = /^https:\/\/([^\.]+\.)?youtube\.com\/watch\?v=(.*)/

    this.element = element
    this.editor = element.editor
    this.toolbar = element.toolbarElement
    this.hrefElement = this.toolbar.querySelector("[data-trix-input][name='href']")
    this.embedContainerElement = this.toolbar.querySelector("[data-behavior='embed_container']")
    this.embedElement = this.toolbar.querySelector("[data-behavior='embed_url']")

    this.reset()
    this.installEventHandlers()
  }

  installEventHandlers() {
    this.hrefElement.addEventListener("input", this.didInput.bind(this))
    this.hrefElement.addEventListener("focusin", this.didInput.bind(this))
    this.embedElement.addEventListener("click", this.embed.bind(this))
  }

  didInput(event) {
    let value = event.target.value.trim()
    let matches = value.match(this.pattern)
    console.log(value, matches)

    // When patterns are loaded, we can just fetch the embed code
    if (matches != null) {
      this.fetch(matches[2])

    // No embed code, just reset the form
    } else {
      this.reset()
    }
  }

  fetch(value) {
    Rails.ajax({
      url: `/youtube/${encodeURIComponent(value)}`,
      type: 'get',
      error: this.reset.bind(this),
      success: this.showEmbed.bind(this)
    })
  }

  embed(event) {
    if (this.currentEmbed == null)
      return

    let attachment = new Trix.Attachment(this.currentEmbed)
    this.editor.insertAttachment(attachment)
    this.element.focus()
  }

  showEmbed(embed) {
    this.currentEmbed = embed
    this.embedContainerElement.style.display = "block"
  }

  reset() {
    this.embedContainerElement.style.display = "none"
    this.currentEmbed = null
  }
}

document.addEventListener("trix-initialize", function(event) {
  new YoutubeEmbedController(event.target)
  new VideoEmbedController(event.target)
})
