import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [ "items" ]

  initialize () {
    this.resizeObserver = new ResizeObserver(this.normalizeHeights.bind(this))
  }

  connect() {
    this.items = this.itemsTarget.querySelectorAll('.carousel-item')
    this.heights = []
    this.maxHeight = 0;

    this.items.forEach(element => {
      this.resizeObserver.observe(element)
    });
  }

  disconnect() {
    this.items.forEach(element => {
      this.resizeObserver.unobserve(element)
    });
  }

  normalizeHeights() {
    this.items.forEach(element => {
      // Force element to be visible so we get a valid height
      element.style.display = 'block'
      this.heights.push(element.offsetHeight)

      // Allow classes to control visibility again
      element.style.display = ''
    });

    this.maxHeight = this.heights.reduce(function(a, b) { return Math.max(a, b); })

    this.items.forEach(element => {
      element.style.minHeight = `${this.maxHeight}px`
    });

    this.heights = []
  }
}
