import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [ "items", "mainImage" ]

  connect () {
    this.sources = []
    this.currentIndex = 0

    var images = this.itemsTarget.querySelectorAll("img")
    images.forEach(element => {
      this.sources.push(element.src)
    });
  }

  showImage(event) {
    event.preventDefault()

    const pred = (value) => value == event.target.src;
    this.currentIndex = this.sources.findIndex(pred)
    this.showImageIndex(this.currentIndex)
  }

  showNextImage() {
    this.currentIndex = mod(this.currentIndex + 1, this.sources.length)
    this.showImageIndex(this.currentIndex)
  }

  showPreviousImage() {
    this.currentIndex = mod(this.currentIndex - 1, this.sources.length)
    this.showImageIndex(this.currentIndex)
  }

  showImageIndex(imageIndex) {
    this.mainImageTarget.src = this.sources[imageIndex]
  }
}

function mod(n, m) {
  return ((n % m) + m) % m;
}
