// video_controller.js
import { Controller } from 'stimulus';

export default class extends Controller {
  connect() {
    console.log('Hello, Stimulus!', this.element);
    this.playerTarget.addEventListener('timeupdate', this.updateSeekBar.bind(this));
    this.playerTarget.addEventListener('loadedmetadata', this.updateDuration.bind(this));
    this.playerTarget.addEventListener('ended', this.handleVideoEnd.bind(this));
    this.playerTarget.addEventListener('play', this.handleVideoPlay.bind(this));

    // this.updatePlayPauseButton();
    this.updateMuteButton();
  }

  static targets = ['player', 'seekBar', 'volumeBar', 'playPauseBtn', 'largePlayBtn', 'muteBtn', 'currentTime', 'duration', 'thumbnail'];
  
  handleVideoPlay() {
    this.thumbnailTarget.style.display = 'none';
  }
  
  handleVideoEnd() {
    this.updatePlayPauseButton();
    this.playerTarget.currentTime = 0;
    this.thumbnailTarget.style.display = 'block';
  }
  
  togglePlayPause() {
    if (this.playerTarget.paused) {
      this.playerTarget.play();
    } else {
      this.playerTarget.pause();
    }
    this.updatePlayPauseButton();
  }

  seek() {
    const time = this.playerTarget.duration * (this.seekBarTarget.value / 100);
    this.playerTarget.currentTime = time;
  }

  toggleMute() {
    this.playerTarget.muted = !this.playerTarget.muted;
    this.updateMuteButton();
  }

  changeVolume() {
    this.playerTarget.volume = this.volumeBarTarget.value;
    this.updateMuteButton();
  }

  updateSeekBar() {
    const value = (100 / this.playerTarget.duration) * this.playerTarget.currentTime;
    this.seekBarTarget.value = value;
    this.currentTimeTarget.textContent = this.formatTime(this.playerTarget.currentTime);
  }

  updateDuration() {
    this.durationTarget.textContent = this.formatTime(this.playerTarget.duration);
  }

  formatTime(time) {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  }

  toggleFullScreen() {
    if (document.fullscreenElement) {
      document.exitFullscreen();
      this.element.classList.remove('fullscreen');
    } else {
      this.element.requestFullscreen();
      this.element.classList.remove('fullscreen');
    }
  }

  updatePlayPauseButton() {
    console.log(this.playerTarget.paused);
    if (this.playerTarget.paused) {
      this.playPauseBtnTarget.classList.remove('playing');
      this.largePlayBtnTarget.classList.remove('playing');
    } else {
      this.playPauseBtnTarget.classList.add('playing');
      this.largePlayBtnTarget.classList.add('playing');
    }
  }

  updateMuteButton() {
    if (this.playerTarget.muted || this.playerTarget.volume === 0) {
      this.muteBtnTarget.classList.add('muted');
    } else {
      this.muteBtnTarget.classList.remove('muted');
    }
  }
}