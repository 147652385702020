'use strict';

import Rails from "@rails/ujs"
import Wistia from './api.js.erb'

document.addEventListener("turbolinks:load", () => {
  let videoUploadElement = document.querySelector("#wistia_uploader")
  if (videoUploadElement !== null)
    setupVideoUpload()
})

function setupVideoUpload() {
  console.log("Hello from setupVideoUpload()")

  window._wapiq = window._wapiq || [];
  _wapiq.push(function(W) {
    const wistiaApiKey = Wistia.API_KEY
    const wistiaProjectId = document.querySelector("meta[name='wistia-project-id']").getAttribute("content")

    window.wistiaUploader = new W.Uploader({
      accessToken: wistiaApiKey,
      dropIn: "wistia_uploader",
      projectId: wistiaProjectId
    });

    wistiaUploader.bind('uploadsuccess', function(file, media) {
      console.log("The upload succeeded. Here's the media object!", media);
      wistiaUploader.previewElem.style.border = "none";
    });

    wistiaUploader.bind("uploadembeddable", function(file, media, embedCode, oembedResponse) {
      console.log("The video is now embeddable!");
      console.log("Here's the original file: ", file);
      console.log("Here's the media in Wistia: ", media);
      console.log("Here's an embed code: ", embedCode);
      console.log("And here's the full oEmbed response (see https://wistia.com/doc/oembed): ", oembedResponse);

      // Save the video details to the database
      createVideo(media);
    });
  });
}

function createVideo(media) {
  const clientId = document.querySelector("meta[name='client-id']").getAttribute("content")

  let video_data = "video[client_id]=" + clientId
                 + "&video[title]=" + media.attributes["name"]
                 + "&video[duration]=" + media.duration
                 + "&video[wistia_media_id]=" + media.id

  console.log("Here's the url parameters to create the video:", video_data)

  Rails.ajax({
    type: "POST",
    url: "/content/videos/",
    dataType: 'script',
    data: video_data
  })
}
